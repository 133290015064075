import React, { Component } from 'react';


export default class Servicess2 extends Component {

  render() {

    return (
      <section className="container services-section2">
        <div className="bg-noise"></div>
        <div className="sub-s2">
          <div className='work-project'>
            <ul className="nav nav-tabs " id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="active button" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">
                      <span data-text="All works">
                        <i>All works</i>
                      </span>
                </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="button" id="web-tab" data-bs-toggle="tab" data-bs-target="#web" type="button" role="tab" aria-controls="web" aria-selected="false">
                      <span data-text="Websites">
                        <i>Websites</i>
                      </span>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="button" id="products-tab" data-bs-toggle="tab" data-bs-target="#products" type="button" role="tab" aria-controls="products" aria-selected="false">
                      <span data-text="Products">
                        <i>Products</i>
                      </span>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="button" id="web3-tab" data-bs-toggle="tab" data-bs-target="#web3" type="button" role="tab" aria-controls="web3" aria-selected="false">
                      <span data-text="Web3">
                        <i>Web3</i>
                      </span>
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className='work-flex'>
                    <div className='inner-project'>
                      <a href='/work/yugadecors'>YUGA DECORS</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-yuga.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>YUGA DECORS</h4>
                            <h5>E-COMMERCE</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/work/coinguard'>COINGUARD</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-coinguard.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>COINGUARD</h4>
                            <h5>MOBILE APPLICATION, WEB3</h5>
                        </div>
                    </div>
                  </div>
                  <div className='work-flex'>
                    <div className='inner-project'>
                      <a href='/work/designlane'>DESIGNLANE</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-designlane.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>DESIGNLANE</h4>
                            <h5>WEBSITE</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/work/bookingvista'>BOOKING VISTA</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work---bookingvista.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>BOOKING VISTA</h4>
                            <h5>MOBILE APPLICATION</h5>
                        </div>
                    </div>
                  </div>
                  <div className='work-flex'>
                  <div className='inner-project'>
                      <a href='/404'>DOODLES</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-doodles.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>DOODLES</h4>
                            <h5>WEB3, NFT</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/404'>SCAYLE</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-scayle.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>SCAYLE</h4>
                            <h5>WEBSITE</h5>
                        </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="web" role="tabpanel" aria-labelledby="web-tab">
                <div className='work-flex'>
                    <div className='inner-project'>
                      <a href='/work/designlane'>DESIGNLANE</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-designlane.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>DESIGNLANE</h4>
                            <h5>WEBSITE</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/404'>YUGA DECORS</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-yuga.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>YUGA DECORS</h4>
                            <h5>E-COMMERCE</h5>
                        </div>
                    </div>

                  </div>
                  <div className='work-flex'>
                  <div className='inner-project'>
                      <a href='/404'>SCAYLE</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-scayle.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>SCAYLE</h4>
                            <h5>WEBSITE</h5>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="products" role="tabpanel" aria-labelledby="products-tab">
                <div className='work-flex'>
                    <div className='inner-project'>
                      <a href='/work/coinguard'>COINGUARD</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-coinguard.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>COINGUARD</h4>
                            <h5>MOBILE APPLICATION, WEB3</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/work/bookingvista'>BOOKING VISTA</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work---bookingvista.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>BOOKING VISTA</h4>
                            <h5>MOBILE APPLICATION</h5>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="web3" role="tabpanel" aria-labelledby="web3-tab">
                <div className='work-flex'>
                    <div className='inner-project'>
                      <a href='/404'>DOODLES</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-doodles.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>DOODLES</h4>
                            <h5>WEB3, NFT</h5>
                        </div>
                    </div>
                    <div className='inner-project'>
                      <a href='/work/coinguard'>COINGUARD</a>
                      <img width="620" height='570' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-coinguard.png' alt='onceadev project title'/>
                        <div className='project-border'>
                            <h4>COINGUARD</h4>
                            <h5>MOBILE APPLICATION, WEB3</h5>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
