import React, { Component } from 'react'

export default class About extends Component {

  render() {

    return (
        <div className='container home-about'>
            <h2 data-splitting="chars">At Onceadev, we <br className='mob-br'/><br className='tab-br'/>specialize in <br className='desk-br'/>turning <br className='mob-br'/><br className='tab-br'/>your ideas into <br className='mob-br'/><br className='tab-br'/><br className='desk-br'/>exceptional digital <br className='mob-br'/><br className='tab-br'/>products. <br className='desk-br'/>With <br className='mob-br'/><br className='tab-br'/>creativity and <br className='mob-br'/><br className='tab-br'/>expertise, we <br className='desk-br'/>deliver <br className='mob-br'/><br className='tab-br'/>captivating solutions <br className='mob-br'/><br className='tab-br'/><br className='desk-br'/>that inspire. We <br className='mob-br'/>have <br className='tab-br'/>expertise <br className='desk-br'/>in <br className='mob-br'/>areas <br className='tab-br'/>such as</h2>
        </div>
    )

  }
}
