import React, { Component } from 'react'


export default class Studiovalues extends Component {

  render() {

    return (
        <div className='studio-values'>
            <div className='full-width studio-f-img'>
            </div>
            <div className='container s-values-1'>
                <span className='sub-heading'>values</span>
                <div className='hwd-content'>
                    <div className='hwd-c-left'>
                        <h2>Our Values define the <br/>essence of our studio.</h2>
                    </div>
                </div>

                <div className='studio-values-flex studio-values-flex1'>
                    <div className='left'>
                        <h3>Simplicity</h3>
                        <p>We create clean and minimalist designs that convey messages <br className='tab-br'/><br className='desk-br'/> effectively and leave a lasting impact on the audience </p>
                    </div>
                    <div className='right'>
                        <h3>Authenticity</h3>
                        <p>We avoid generic templates and instead craft tailored solutions <br className='tab-br'/><br className='desk-br'/> that capture the essence of each project, helping our clients <br className='tab-br'/><br className='desk-br'/> stand out from the crowd.</p>
                    </div>
                </div>
                <div className='studio-values-flex'>
                    <div className='left'>
                        <h3>Elegance</h3>
                        <p>Our design approach is rooted in elegance, leaving a <br className='desk-br'/>lasting <br className='tab-br'/>impression on viewers and elevating the overall <br className='desk-br'/>brand image.</p>
                    </div>
                    <div className='right'>
                        <h3>Human-centred</h3>
                        <p>We place a strong emphasis on the human element in design. <br className='desk-br'/>We <br className='tab-br'/>use empathetic language and focus on user-centric <br className='desk-br'/>experiences <br className='tab-br'/>to create designs that resonate with people on <br className='desk-br'/>a personal level.</p>
                    </div>
                </div>
            </div>

        </div>
    )

  }
}
