import React, { Component } from 'react'


export default class StudioFAQ extends Component {

  render() {

    return (
        <div className='container studio-faq'>
            <span className='sub-heading'>FAQ</span>
            <div className='hwd-content'>
                <div className='hwd-c-left'>
                    <h2>Have any questions?</h2>
                </div>
            </div>

            <div className="accordion faq" id="accordionFlushExample">
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                    What makes you different from a traditional agency?
                    </button>
                    </h3>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>Speed, quality and complete customisation under one roof. Most agencies lack one or more of these, we will never compromise on these. We also adopt and implements new and improved processes, tech and systems at a pace that other agencies cannot. Simply due to our flexible core setup and the understanding.</p></div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Which clients do you work with?
                    </button>
                    </h3>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>We are particularly drawn to client's goals, missions, and stories; so, if you feel you have a great purpose, we’d love to see how we can help you realise it. We thrive on transforming remarkable visions into extraordinary realities, partnering with clients who dare to dream big.</p></div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    What is your process?
                    </button>
                    </h3>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>Our straightforward process begins with actively listening to understand your needs, goals, and vision. We collaboratively plan and visualise your functional yet beautiful website. With our unique touch, the UI-stage brings the "Wow Factor." We then facilitate the handover to the developer for seamless implementation.</p></div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    How long does a project take?
                    </button>
                    </h3>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>Each project is different, but it can be from 1 up to 8 weeks. Factors like communication with the client and feedback can delay the project conclusion. If your website's content is ready before inquiring, everything will go faster and smoother.</p></div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    How much does it cost to work with you?
                    </button>
                    </h3>
                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>Our project-based pricing is customised to fit your unique needs and goals. We consider factors like style, audience, complexity, and deadline. Contact us to align your vision with your budget.</p></div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h3 className="accordion-header" id="flush-headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    How does the payment work?
                    </button>
                    </h3>
                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><p>Our payment structure typically involves a 25% upfront payment, 50% before starting the development and the remaining 25% after the website launch, which can be adjusted depending on the project size.</p></div>
                    </div>
                </div>
            </div>

        </div>
    )

  }
}
