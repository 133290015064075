import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/work.css"
import "./styles/work-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class BookingVista extends Component { 

workAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

    // all 
    "all": function() {

    },
    
    // desktop
    "(min-width: 992px)": function() {

        //Main
      gsap.to("main", {
        borderBottomRightRadius:"4vw",
        borderBottomLeftRadius:"4vw",
        scrollTrigger: {
        trigger: "main",
        start: "bottom 100%",
        end: "bottom 20%",
        scrub: true
      }
      });

      gsap.set('.work-page-hi', { y: 0});
      gsap.to(".work-page-hi", {
        y: "-10.944vw",
        scrollTrigger: {
          trigger: ".work-page-hi",
          scrub: true
        }
      });
    }, 

    // Tab
    "(max-width: 991px) and (min-width: 744px)": function() {
  
    },

    // Mobile
    "(max-width: 743px)": function() {

    }, 
  
  });




  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerHeight * 0.2) {
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.workAnimation();
}


  render() {

    return (
      <div className='work-page bookingvista'>
        <Header/>
        <main>
        <div className="bg-noise"></div>
            <div className='work-hero container'>
                <div className='hero-content'>
                    <span className='sub-head'>BOOKING VISTA</span>
                    <h1>Elevating Travel With <br/>Easy Hotel Bookings</h1>
                </div>
                <div className='work-hero-img-div'>
                  <img className='work-page-hi img-fluid' width="1300" height="900" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-1.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>OVERVIEW</span>
                <p>Booking Vista project aimed to optimize the app's user interface, ensuring a seamless and enjoyable hotel booking experience for travelers. Through a combination of extensive UX research and thoughtful UI design, the goal was to create an intuitive and visually engaging platform that would cater to a diverse range of users and elevate the hotel booking process.</p>

                <div className='project-details'>
                  <div className='pd-flex'>
                    <h6>Client</h6>
                    <h6>Booking Vista</h6>
                  </div>
                  <div className='pd-flex'>
                    <h6>Industry</h6>
                    <h6>Web 3, Blockchain</h6>
                  </div>
                  <div className='pd-flex last'>
                    <h6>Services</h6>
                    <h6>UX Research, UI Design</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl2 big' width="1260" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-2.png' alt='bookingvista travel hotel booking ONCEADEV'/>
              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg'>
                    <img className='img-fluid dl4 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-3.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg l1'>
                  <img className='img-fluid dl4 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-4.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>CHALLENGE</span>
                <p>Conducting comprehensive UX research to uncover user expectations and integrating user feedback into the design while maintaining a cohesive and user-friendly UI presented challenges. Striking the right balance between simplicity and comprehensive features was vital to ensure a seamless and satisfying booking process.</p>
              </div>
            </div>
            <div className='container mock mock2'>
            <div className='mock-flex'>
                  <img className='img-fluid mockimg half-mockimgin dl8' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-5.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                  <img className='img-fluid mockimg half-mockimgin dl9' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-6.png' alt='bookingvista travel hotel booking ONCEADEV'/>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl7 big' width="1260" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-7.png' alt='bookingvista travel hotel booking ONCEADEV'/>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>SOLUTION</span>
                <p>Our hotel booking application solves these problems by providing a user-friendly interface that consolidates all the necessary information in one place. Users can easily search for hotels based on their destination, travel dates, and specific requirements. Our application presents comprehensive hotel profiles, including details on amenities, room types, photos, user reviews, and ratings.</p>
                <br/>
                <p>The booking process is streamlined, allowing users to view real-time availability and make secure reservations directly through the app. We prioritize data security and privacy, adhering to industry standards to ensure users' personal and financial information is protected.</p>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid big' width="1045" height="662" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-8.png' alt='bookingvista travel hotel booking ONCEADEV'/>
              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg l2'>
                  <img className='img-fluid dl5 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista--9.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg l3'>
                  <img className='img-fluid dl6 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/bookingvista/tr:f-auto/bookingvista-10.png' alt='bookingvista travel hotel booking ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='related'>
                <div className='content'>
                    <span className='sub-head'>NEXT UP</span>
                    <h5>YUGADECORS</h5>
                </div>
                <div className='overflow-image'>
                    <img width="620" height="570" className='next-project img-fluid' src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-yuga.png" alt="onceadev YUGADECORS" />
                </div>
                <a className='linka' href='/work/yugadecors'>yugadecors</a>
            </div>
        </main>
        <Footer/>
        <div className='cursor'>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
      </div>
    )

  }
}
