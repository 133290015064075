import React, { Component } from 'react'


export default class Work extends Component {


  render() {

    return (
        <div className='work'>
            <div className='container heading'>
                <div className='row'>
                    <div className='col-lg-6 left'>
                        <h3>Featured Projects</h3>
                    </div>
                    <div className='col-lg-6 right desk-br'>
                        <p>Projects we are proud of</p>
                    </div>
                </div>
            </div>
            <div className='container projects'>
                <div className='row'>
                    <div className='col-lg-6 project1'>
                        <a href='/work/yugadecors'>YUGA DECORS</a>
                        <img width="620" height='630' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/home-yuga.png' alt='onceadev project YUGA DECORS Furniture E-COMMERCE'/>
                        <div className='project-border'>
                            <h4>YUGA DECORS</h4>
                            <h5>E-COMMERCE</h5>
                        </div>
                    </div>
                    <div className='col-lg-1'>
                    </div>
                    <div className='col-lg-5 project2'>
                        <a href='/work/bookingvista'>BOOKING VISTA</a>
                        <img width="520" height='630' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/home---booking-vista.png' alt='onceadev projects BOOKING VISTA Hotel booking MOBILE APPLICATION'/>
                        <div className='project-border'>
                            <h4>BOOKING VISTA</h4>
                            <h5>MOBILE APPLICATION</h5>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-5 project3'>
                        <a href='/work/designlane'>DESIGNLANE</a>
                        <img width="520" height='630' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/home-designlane.png' alt='onceadev project DESIGNLANE Architecture website'/>
                        <div className='project-border'>
                            <h4>DESIGNLANE</h4>
                            <h5>WEBSITE</h5>
                        </div>
                    </div>
                    <div className='col-lg-1'>
                    </div>
                    <div className='col-lg-6 project4'>
                        <a href='/work/coinguard'>COINGUARD</a>
                        <img width="620" height='630' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/home-coinguard.png' alt='onceadev project COINGUARD web3, crypto app'/>
                        <div className='project-border'>
                            <h4>COINGUARD</h4>
                            <h5>WEB 3, MOBILE APPLICATION</h5>
                        </div>
                    </div>
                </div>

                <img width="700" height='700' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-bg.png' className='work-bg' alt='onceadev projects decoration'/>
            </div>
            <div className='container work-cta'>
                <a href='/work' className='button' >
                    <span data-text="More works">
                        <i>More works</i>
                    </span>
                </a>
            </div>
        </div>
    )

  }
}
