import React, { Component } from 'react'

export default class Loader extends Component {

  render() {

    return (
        <div className='home-loader'>
            <h4>
                <span>O</span>
                <span>n</span>
                <span>c</span>
                <span>e</span>
                <span>a</span>
                <span>d</span>
                <span>e</span>
                <span>v</span>
            </h4>
        </div>
    )

  }
}
