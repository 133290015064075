import React, { Component } from 'react'
// import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import Hero from './services-components/services-hero'
import Servicess2 from './services-components/services-sec2'
import { Helmet } from 'react-helmet'



export default class Services extends Component { 

servicesAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

      // all 
      "all": function() {
        // Loader
        gsap.to(".sub-loader", {
          opacity: 0,
          ease: "Power3.easeOut",
          delay: 1.6,
        })


        gsap.from(".sub-loader h4 span", {
          y: 100,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: .3,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })

        gsap.to(".sub-loader h4 span", {
          y: -80,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: 1,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })
      },
      
      // desktop
      "(min-width: 1024px)": function() {

        gsap.to("main", {
          overflow: "hidden",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 120%",
            scrub: true
          }
        });

        gsap.to("main", {
          borderBottomRightRadius:"4vw",
          borderBottomLeftRadius:"4vw",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 100%",
            scrub: true
          }
        });

        gsap.set('.services-hero h1', { y: 0});
        gsap.to(".services-hero h1", {
          y: -70,
          scrollTrigger: {
            trigger: ".services-hero h1",
            start: "top 20%",
            scrub: true
          }
        });

        
      }, 
  
      // mobile
      "(max-width: 1023px)": function() {

      }, 
  
  });

//   $('.contact-form label, .contact-form input, .contact-form textarea').hover(function() {
//     $('.circle').css('width', '0');
//     $('.circle').css('height', '0');
//   }, function() {
//     $('.circle').css('width', '');
//     $('.circle').css('height', '');
//   });


  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerWidth * 0.2) {
      // Hero Section height in vw at 1440 is (73.021vw) which is converted to this code above
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.servicesAnimation();
}


  render() {

    return (
      <div className='sub-page services'>
        <Helmet>
          <title>Onceadev Expertise | Leading Web and Marketing Solutions</title>
          <meta name="title" content="Onceadev Expertise | Leading Web and Marketing Solutions" />
          <meta name="description"content="Discover Onceadev's comprehensive digital services, from UI/UX design to web development and marketing strategies. Elevate your brand with our expert solutions."/>
          <meta property="og:title" content="Services" />
          <meta name="og:description"content="Discover Onceadev's comprehensive digital services, from UI/UX design to web development and marketing strategies. Elevate your brand with our expert solutions."/>
        </Helmet>
        <div className='sub-loader'>
            <h4>
                <span>S</span>
                <span>e</span>
                <span>r</span>
                <span>v</span>
                <span>i</span>
                <span>c</span>
                <span>e</span>
                <span>s</span>
            </h4>
        </div>
        <Header/>
        <main>
        <Hero/>
        <Servicess2/>

        </main>
        <Footer/>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
