import React, { Component } from 'react'


export default class Testimonial extends Component {

  render() {

    return (
        <div className='testimonials'>
            <div className='container heading'>
                <div className='row'>
                    <div className='col-lg-6 left'>
                        <h3>Testimonials</h3>
                    </div>
                    <div className='col-lg-6 right desk-br'>
                        <p>Winning hearts and trust</p>
                    </div>
                </div>
            </div>
            <div className='container testimonial-each testimonial-each1'>
                <div className='content'>
                    <h4 data-splitting="chars" className='t-one'>“Onceadev is composed of a <br/>team of talented designers <br/>with a passion for excellence.”</h4>
                    <h5>Albert Flores</h5>
                    <h6>President of Sales</h6>
                </div>
                {/* <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/dummy-testimonial.png' className='testimonial' width="120" height="120" alt='testimonial'/> */}
            </div>
            <div className='container testimonial-each testimonial-each2'>
                <div className='content'>
                    <h4 data-splitting="chars" className='t-two'>“Working together we created a <br/>brand that has been very well <br/>received by the market. <br/>Excellent work”</h4>
                    <h5>Shalini Jain</h5>
                    <h6>Marketing Coordinator</h6>
                </div>
                {/* <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/dummy-testimonial.png' className='testimonial' width="120" height="120" alt='testimonial'/> */}
            </div>
            <div className='container testimonial-each testimonial-each3'>
                <div className='content'>
                    <h4 data-splitting="chars" className='t-three'>“They skillfully and meticulously <br/>create new innovative designs <br/>without changing our brand DNA”</h4>
                    <h5>Rupam Ghosh</h5>
                    <h6>Chief Executive Officer</h6>
                </div>
                {/* <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/dummy-testimonial.png' className='testimonial' width="120" height="120" alt='testimonial'/> */}
            </div>
        </div>
    )

  }
}
