import React, { Component } from 'react'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class Comingsoon extends Component {

  render() {

    return (
      <div className='extra-page lost'>
        <div className='d-none'><Header/></div>
        <h3>Shaping the <br className='tab-br'/><br className='mob-br'/>Digital Landscape</h3>
        <p>Attention entrepreneurs and businesses! <br className='tab-br'/><br className='mob-br'/> Elevate your online presence. <br className='desk-br'/>Stay tuned for <br className='mob-br'/>our <br className='tab-br'/> exclusive digital solutions. Coming soon, <br className='tab-br'/><br className='mob-br'/> <br className='desk-br'/>exclusively for you.</p>
        <div className='d-none'><Footer/></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
