import React, { Component } from 'react'
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap';


export default class Header extends Component {

  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.targetDivRef = React.createRef();
    this.closeSvgRef = React.createRef();
    this.listItemsRef = [];
    this.isScaled = false;
  }

  componentDidMount() {
    this.svgRef.current.addEventListener('click', this.animateDiv);
    this.closeSvgRef.current.addEventListener('click', this.closeDiv);
  }

  componentWillUnmount() {
    this.svgRef.current.removeEventListener('click', this.animateDiv);
    this.closeSvgRef.current.removeEventListener('click', this.closeDiv);
  }

  animateDiv = () => {
    const targetDiv = this.targetDivRef.current;
  
    if (this.isScaled) {
      const timeline = gsap.timeline({
        onComplete: () => {
          gsap.set(this.listItemsRef, { opacity: 0 });
        }
      });
  
      timeline.to(targetDiv, {
        scale: 0,
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          gsap.to(this.listItemsRef, {
            opacity: 0,
            duration: 0.2,
          });
        }
      }).to(this.svgRef.current, {
        rotation: 0,
        duration: 0.3,
      }).to(this.closeSvgRef.current, {
        rotation: 0,
        duration: 0.3,
      });
    } else {
      gsap.set(this.listItemsRef, { opacity: 0 });
  
      const timeline = gsap.timeline();
  
      timeline.to(targetDiv, {
        scale: 1,
        opacity: 1,
        duration: 0.3,
        onComplete: () => {
          gsap.to(this.listItemsRef, {
            opacity: 1,
            duration: 0.2,
            stagger: 0.1,
          });
        }
      }).to(this.svgRef.current, {
        rotation: 45,
        duration: 0.3,
      }).to(this.closeSvgRef.current, {
        rotation: 45,
        duration: 0.3,
      });
    }
  
    this.isScaled = !this.isScaled;
  };

  closeDiv = (event) => {
    event.stopPropagation();
    const targetDiv = this.targetDivRef.current;

    gsap.to(targetDiv, {
      scale: 0,
      opacity: 0,
      delay: 0.5,
      duration: 0.3,
    });
    gsap.to(this.svgRef.current, {
      rotation: 0,
      duration: 0.3,
    });
    gsap.to(this.closeSvgRef.current, {
      rotation: 0,
      delay: 0,
      duration: 0.3,
    })
    gsap.to(this.listItemsRef, {
      opacity: 0,
      duration: 0.3,
      stagger: 0.1,
    });

    this.isScaled = false;
  };


  render() {

    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), 
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }


    requestAnimationFrame(raf)

    return (
      <div className='head-cover'>
        <div className='header-p'>
          <header id='header'>
            <div className='container desktop'>
              <div className='row'>
                <div className='col-lg-4 col-6 left'>
                  <a href='/' className='menu-item-d' aria-label="Onceadev">
                  <svg viewBox="0 0 148 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g className='logo-mark'>
                      <rect width="24" height="24" rx="3.88971" fill="white"/>
                      <path d="M11.3249 8.10916C11.3232 7.84064 11.1038 7.62241 10.8362 7.64474C9.33827 7.76979 7.92774 8.42566 6.86427 9.50283C5.6865 10.6958 5.03086 12.3077 5.04159 13.984C5.05232 15.6604 5.72852 17.2638 6.92146 18.4416C7.99863 19.505 9.41744 20.1428 10.9168 20.2487C11.1847 20.2676 11.4013 20.0466 11.3996 19.778L11.3622 13.9436L11.3249 8.10916Z" fill="black"/>
                      <path d="M12 4.52598C11.9983 4.25746 12.2149 4.03644 12.4828 4.05535C13.9821 4.16121 15.401 4.79898 16.4781 5.86246C17.6711 7.04022 18.3473 8.64364 18.358 10.32C18.3687 11.9963 17.7131 13.6083 16.5353 14.8012C15.4718 15.8784 14.0613 16.5342 12.5634 16.6593C12.2958 16.6816 12.0764 16.4634 12.0747 16.1949L12.0374 10.3604L12 4.52598Z" fill="black"/>
                    </g>
                    <g className='word-mark'>
                      <path d="M43.9808 18.359C42.327 19.9968 40.2476 20.8157 37.7428 20.8157C35.238 20.8157 33.1587 19.9968 31.5048 18.359C29.8349 16.6891 29 14.6419 29 12.2174C29 9.77677 29.8349 7.73759 31.5048 6.09982C33.1587 4.44599 35.238 3.61907 37.7428 3.61907C40.2476 3.61907 42.327 4.44599 43.9808 6.09982C45.6507 7.73759 46.4856 9.77677 46.4856 12.2174C46.4856 14.6419 45.6507 16.6891 43.9808 18.359ZM37.7428 18.0459C39.3966 18.0459 40.7775 17.492 41.8854 16.3841C42.9933 15.2601 43.5473 13.8712 43.5473 12.2174C43.5473 10.5635 42.9933 9.18268 41.8854 8.07478C40.7775 6.95082 39.3966 6.38884 37.7428 6.38884C36.105 6.38884 34.7322 6.95082 33.6243 8.07478C32.5164 9.18268 31.9624 10.5635 31.9624 12.2174C31.9624 13.8712 32.5164 15.2601 33.6243 16.3841C34.7322 17.492 36.105 18.0459 37.7428 18.0459Z" fill="white"/>
                      <path d="M55.8477 7.95435C57.3088 7.95435 58.4809 8.39591 59.3641 9.27902C60.2632 10.1621 60.7128 11.3343 60.7128 12.7954V20.5267H58.0153V13.6866C58.0153 12.7392 57.7343 11.9846 57.1723 11.4226C56.6264 10.8445 55.8878 10.5555 54.9565 10.5555C53.9771 10.5555 53.1903 10.8526 52.5962 11.4467C52.0021 12.0247 51.7051 12.7874 51.7051 13.7347V20.5267H49.0076V8.24337H51.7051V9.9534C52.7006 8.6207 54.0814 7.95435 55.8477 7.95435Z" fill="white"/>
                      <path d="M69.5363 20.8157C67.6417 20.8157 66.0762 20.2055 64.8398 18.9852C63.5874 17.7649 62.9612 16.2315 62.9612 14.385C62.9612 12.5706 63.5874 11.0452 64.8398 9.80889C66.1083 8.57253 67.6738 7.95435 69.5363 7.95435C71.142 7.95435 72.5309 8.41999 73.703 9.35127C74.8752 10.2826 75.6218 11.4868 75.9429 12.964H73.0768C72.7878 12.2254 72.3222 11.6393 71.6799 11.2058C71.0537 10.7723 70.3392 10.5555 69.5363 10.5555C68.4606 10.5555 67.5534 10.9248 66.8148 11.6634C66.0922 12.386 65.7309 13.2932 65.7309 14.385C65.7309 15.4608 66.0922 16.376 66.8148 17.1307C67.5694 17.8532 68.4766 18.2145 69.5363 18.2145C70.3392 18.2145 71.0537 17.9977 71.6799 17.5642C72.3222 17.1307 72.7878 16.5446 73.0768 15.806H75.9429C75.6218 17.2832 74.8752 18.4875 73.703 19.4188C72.5309 20.35 71.142 20.8157 69.5363 20.8157Z" fill="white"/>
                      <path d="M90.6936 14.4091C90.6936 14.7945 90.6695 15.1316 90.6213 15.4207H80.4575C80.6502 16.3198 81.0757 17.0424 81.734 17.5883C82.4084 18.1182 83.2112 18.3831 84.1425 18.3831C84.9132 18.3831 85.5956 18.2225 86.1897 17.9014C86.7999 17.5803 87.2414 17.1548 87.5144 16.6249H90.4527C90.0032 17.8773 89.2084 18.8889 88.0683 19.6596C86.9283 20.4303 85.6117 20.8157 84.1184 20.8157C82.2398 20.8157 80.6823 20.2055 79.446 18.9852C78.1935 17.7649 77.5673 16.2315 77.5673 14.385C77.5673 12.5706 78.1935 11.0452 79.446 9.80889C80.7144 8.57253 82.2719 7.95435 84.1184 7.95435C85.981 7.95435 87.5465 8.57253 88.815 9.80889C90.0674 11.0292 90.6936 12.5626 90.6936 14.4091ZM80.4816 13.2049H87.7793C87.5545 12.3539 87.1049 11.6715 86.4306 11.1576C85.7722 10.6438 85.0015 10.3869 84.1184 10.3869C83.2353 10.3869 82.4646 10.6438 81.8063 11.1576C81.148 11.6715 80.7064 12.3539 80.4816 13.2049Z" fill="white"/>
                      <path d="M102.553 8.24337V18.07H104.046V20.5267H100.169V19.1297C99.3015 20.2537 98.0892 20.8157 96.5317 20.8157C95.2472 20.8157 94.1875 20.4303 93.3525 19.6596C92.5176 18.8728 92.1001 17.8934 92.1001 16.7212C92.1001 15.5652 92.5176 14.6018 93.3525 13.8311C94.1875 13.0443 95.2472 12.6509 96.5317 12.6509H99.8554V10.7964H92.9672V8.24337H102.553ZM96.9893 18.5035C97.8564 18.5035 98.5468 18.3269 99.0606 17.9737C99.5905 17.6044 99.8554 17.1227 99.8554 16.5286V14.9149H96.9893C96.3952 14.9149 95.8895 15.0835 95.472 15.4207C95.0706 15.7579 94.8699 16.1833 94.8699 16.6972C94.8699 17.211 95.0706 17.6445 95.472 17.9977C95.8734 18.3349 96.3792 18.5035 96.9893 18.5035Z" fill="white"/>
                      <path d="M115.585 10.1461V3.18555H118.282V20.5267H115.585V18.624C114.509 20.0851 113.072 20.8157 111.274 20.8157C109.54 20.8157 108.095 20.2055 106.938 18.9852C105.798 17.7489 105.228 16.2155 105.228 14.385C105.228 12.5706 105.798 11.0452 106.938 9.80889C108.095 8.57253 109.54 7.95435 111.274 7.95435C113.072 7.95435 114.509 8.68493 115.585 10.1461ZM111.804 18.2145C112.879 18.2145 113.779 17.8532 114.501 17.1307C115.224 16.376 115.585 15.4608 115.585 14.385C115.585 13.2932 115.224 12.386 114.501 11.6634C113.779 10.9248 112.879 10.5555 111.804 10.5555C110.712 10.5555 109.805 10.9248 109.082 11.6634C108.359 12.386 107.998 13.2932 107.998 14.385C107.998 15.4608 108.359 16.376 109.082 17.1307C109.805 17.8532 110.712 18.2145 111.804 18.2145Z" fill="white"/>
                      <path d="M133.877 14.4091C133.877 14.7945 133.853 15.1316 133.805 15.4207H123.641C123.834 16.3198 124.259 17.0424 124.917 17.5883C125.592 18.1182 126.395 18.3831 127.326 18.3831C128.097 18.3831 128.779 18.2225 129.373 17.9014C129.983 17.5803 130.425 17.1548 130.698 16.6249H133.636C133.187 17.8773 132.392 18.8889 131.252 19.6596C130.112 20.4303 128.795 20.8157 127.302 20.8157C125.423 20.8157 123.866 20.2055 122.629 18.9852C121.377 17.7649 120.751 16.2315 120.751 14.385C120.751 12.5706 121.377 11.0452 122.629 9.80889C123.898 8.57253 125.455 7.95435 127.302 7.95435C129.164 7.95435 130.73 8.57253 131.998 9.80889C133.251 11.0292 133.877 12.5626 133.877 14.4091ZM123.665 13.2049H130.963C130.738 12.3539 130.288 11.6715 129.614 11.1576C128.956 10.6438 128.185 10.3869 127.302 10.3869C126.419 10.3869 125.648 10.6438 124.99 11.1576C124.331 11.6715 123.89 12.3539 123.665 13.2049Z" fill="white"/>
                      <path d="M140.838 17.2029L144.402 8.24337H147.413L142.211 20.5267H139.393L134.166 8.24337H137.249L140.838 17.2029Z" fill="white"/>
                    </g>
                  </svg>
                  
                  </a>
                </div>
                <div className='col-lg-4 middle desk-br'>
                  <a className='menu-item-d' data-text="Work" href='/work'><span>Work</span></a>
                  <a className='menu-item-d' data-text="Studio"  href='/studio'><span>Studio</span></a>
                  <a className='menu-item-d' data-text="Services"  href='/services'><span>Services</span></a>
                  {/* <a className='menu-item-d' data-text="Careers" href='#/'><span>Careers</span></a> */}
                </div>
                <div className='col-lg-4 col-6 right'>
                  <div className="mode-switch">
                    <span className="switch button">
                      <span data-text="switch">
                        <i>switch</i>
                      </span>
                      <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/yy2.png' width='24' height='24' alt='yin yang'/>
                    </span>
                  </div>
                  <a href='/contact' className='button header-btn desk-br'>
                      <span data-text="Contact Us">
                          <i>Contact Us</i>
                      </span>
                  </a>
                  <svg className='menu tab-br mob-br' ref={this.svgRef} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_572_700)">
                    <rect x="0.492174" y="0.492209" width="40.553" height="40.5435" rx="20.2718" transform="matrix(1 4.23198e-05 -2.97034e-05 1 1.46203e-05 -0.0117396)" stroke="white" strokeOpacity="0.3" strokeWidth="0.984376"/>
                    <path d="M25.1875 18.625C25.9123 18.625 26.5 18.0374 26.5 17.3125C26.5 16.5877 25.9124 16 25.1875 16C24.4626 16 23.875 16.5876 23.875 17.3125C23.875 18.0374 24.4626 18.625 25.1875 18.625Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M25.1875 26.5C25.9123 26.5 26.5 25.9124 26.5 25.1875C26.5 24.4627 25.9124 23.875 25.1875 23.875C24.4626 23.875 23.875 24.4626 23.875 25.1875C23.875 25.9124 24.4626 26.5 25.1875 26.5Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.3125 18.625C18.0373 18.625 18.625 18.0374 18.625 17.3125C18.625 16.5877 18.0374 16 17.3125 16C16.5876 16 16 16.5876 16 17.3125C16 18.0374 16.5876 18.625 17.3125 18.625Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.3125 26.5C18.0373 26.5 18.625 25.9124 18.625 25.1875C18.625 24.4627 18.0374 23.875 17.3125 23.875C16.5876 23.875 16 24.4626 16 25.1875C16 25.9124 16.5876 26.5 17.3125 26.5Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_572_700">
                    <rect width="42.0007" height="42.0001" rx="21.0001" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className='menu-box tab-br mob-br' ref={this.targetDivRef}>
          <svg className='menu menu-close tab-br mob-br' ref={this.closeSvgRef} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_572_700)">
            <rect x="0.492174" y="0.492209" width="40.553" height="40.5435" rx="20.2718" transform="matrix(1 4.23198e-05 -2.97034e-05 1 1.46203e-05 -0.0117396)" stroke="white" strokeOpacity="0.3" strokeWidth="0.984376"/>
            <path d="M25.1875 18.625C25.9123 18.625 26.5 18.0374 26.5 17.3125C26.5 16.5877 25.9124 16 25.1875 16C24.4626 16 23.875 16.5876 23.875 17.3125C23.875 18.0374 24.4626 18.625 25.1875 18.625Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.1875 26.5C25.9123 26.5 26.5 25.9124 26.5 25.1875C26.5 24.4627 25.9124 23.875 25.1875 23.875C24.4626 23.875 23.875 24.4626 23.875 25.1875C23.875 25.9124 24.4626 26.5 25.1875 26.5Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3125 18.625C18.0373 18.625 18.625 18.0374 18.625 17.3125C18.625 16.5877 18.0374 16 17.3125 16C16.5876 16 16 16.5876 16 17.3125C16 18.0374 16.5876 18.625 17.3125 18.625Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3125 26.5C18.0373 26.5 18.625 25.9124 18.625 25.1875C18.625 24.4627 18.0374 23.875 17.3125 23.875C16.5876 23.875 16 24.4626 16 25.1875C16 25.9124 16.5876 26.5 17.3125 26.5Z" stroke="white" strokeWidth="2.62501" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_572_700">
            <rect width="42.0007" height="42.0001" rx="21.0001" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          <ul>
            <li ref={(el) => (this.listItemsRef[0] = el)}><a href='/work'>Work</a></li>
            <li ref={(el) => (this.listItemsRef[1] = el)}><a href='/studio'>Studio</a></li>
            <li ref={(el) => (this.listItemsRef[2] = el)}><a href='/services'>Services</a></li>
            <li ref={(el) => (this.listItemsRef[3] = el)}><a href='/contact'>Contact</a></li>
          </ul>
        </div>
      </div>
    )
  }
}
