import React, { Component } from 'react'


export default class StudioHWD extends Component {

  render() {

    return (
        <div className='container studio-hwd'>
            <span className='sub-heading'>HOW WE DO</span>
            <div className='hwd-content'>
                <div className='hwd-c-left'>
                    <h2>Turn your ideas into <br/>digital masterpieces</h2>
                </div>
            </div>

            <div className='hwd-process'>
                <div className='hwd-p-cover'>
                    <div className='left'>
                        <span>01</span>
                        <h3>Discovery</h3>
                    </div>
                    <div className='right'>
                        <ul>
                            <li>Mission</li>
                            <li>Values</li>
                            <li>Teamwork</li>
                            <li>Evaluate</li>
                        </ul>
                    </div>
                </div>
                <div className='hwd-p-cover'>
                    <div className='left'>
                        <span>02</span>
                        <h3>UX / Wireframing</h3>
                    </div>
                    <div className='right'>
                        <ul>
                            <li>Simple</li>
                            <li>Perspective</li>
                            <li>Intuitive</li>
                            <li>User first</li>
                        </ul>
                    </div>
                </div>
                <div className='hwd-p-cover'>
                    <div className='left'>
                        <span>03</span>
                        <h3>Design</h3>
                    </div>
                    <div className='right'>
                        <ul>
                            <li>Narrative</li>
                            <li>Engaging</li>
                            <li>Forward-thinking</li>
                            <li>Distinctive</li>
                        </ul>
                    </div>
                </div>
                <div className='hwd-p-cover'>
                    <div className='left'>
                        <span>04</span>
                        <h3>Development</h3>
                    </div>
                    <div className='right'>
                        <ul>
                            <li>Efficient</li>
                            <li>Robust</li>
                            <li>Responsive</li>
                            <li>Collaborative</li>
                        </ul>
                    </div>
                </div>
                <div className='hwd-p-cover'>
                    <div className='left'>
                        <span>05</span>
                        <h3>Analytics</h3>
                    </div>
                    <div className='right'>
                        <ul>
                            <li>Tracking</li>
                            <li>Optimization</li>
                            <li>Predictive</li>
                            <li>Actionable</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='button-cover'>
                <a href='/services' className='button' >
                    <span data-text="Our services">
                        <i>Our services</i>
                    </span>
                </a>
            </div>

        </div>
    )

  }
}
