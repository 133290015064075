import React, { Component } from 'react'

export default class Hero extends Component {



  render() {

    return (
        <section className='container services-hero'>
          <div className='services-hero-wrapper'>
            <h1>Transforming <br/>Ideas into Reality</h1>
          </div>
        </section>
    )

  }
}
