import React, { Component } from 'react'
import $ from 'jquery'


export default class Hero extends Component {

  heroAnimation = () =>{

    $(function() {
      $('.hero, header, .home-loader').hover(function() {
        $('.circle').css('background-color', 'white');
      }, function() {
        // on mouseout, reset the background color
        $('.circle').css('background-color', '');
      });
    });
    
}


componentDidMount(){
    this.heroAnimation();
}

  render() {

    return (
        <div className='hero'>
          <div className='scroller'>
          <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/scroll.png' className='custom-image' alt='scroll'/>
          </div>
          <div className='bg'>
            <img className='home-hero-bg' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/home-hero-bg.png' alt='onceadev-website-theme'/>
          </div>
          <div className='container content'>
            <div className='h1-wrapper'>
              <h1>
                <div className='line'>
                  <span>Build Brands.</span>
                </div>
                <div className='line'>
                  <span>Design Products.</span>
                </div>
                <div className='line'>
                  <span>Scale Both.</span>
                </div>
              </h1>
            </div>
            <div className="marquee-parent">
                <div className="content"><h2 className='marquee-e'>We create a digital experience that borders on efficiency, aesthetics and functionality.</h2></div>
            </div>
            <div className='third'>
              <div className='row'>
                <div className='col-lg-8 col-md-8 left'>
                  <p>Our goal is to keep brands and businesses <br className='tab-br'/>ahead of the <br className='desk-br'/>game by combining <br className='tab-br'/>technology with top-notch design.</p>
                </div>
                <div className='col-lg-4 col-md-4 right'>
                  <a href='/contact' className='button' >
                    <span data-text="Discuss the project">
                      <i>Discuss the project</i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
    )

  }
}
