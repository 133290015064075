import React, { Component } from 'react'


export default class ContactC extends Component {

  render() {

    return (
        <div className='studio-values contact-values'>
            <span className='sub-heading'>CAREER</span>
            <div className='hwd-content'>
                <div className='hwd-c-left'>
                    <h2>Help us on a mission to <br/>craft digital products.</h2>
                </div>
            </div>

            <div className='studio-career'>
                <div className='studio-career-cover'>
                    <div className='left'>
                        <p>UI/UX Designer</p>
                    </div>
                    <div className='right'>
                        <a href='mailto:onceadev1@gmail.com' target="blank" rel="noreferrer" className='button' >
                        <span data-text="Apply now">
                            <i>Apply now</i>
                        </span>
                        </a>
                    </div>
                </div>
                <div className='studio-career-cover'>
                    <div className='left'>
                        <p>Graphic Designer</p>
                    </div>
                    <div className='right'>
                        <a href='mailto:onceadev1@gmail.com' target="blank" rel="noreferrer" className='button' >
                        <span data-text="Apply now">
                            <i>Apply now</i>
                        </span>
                        </a>
                    </div>
                </div>
                <div className='studio-career-cover last'>
                    <div className='left'>
                        <p>Project Manager</p>
                    </div>
                    <div className='right'>
                        <a href='mailto:onceadev1@gmail.com' target="blank" rel="noreferrer" className='button' >
                        <span data-text="Apply now">
                            <i>Apply now</i>
                        </span>
                        </a>
                    </div>
                </div>

            </div>

        </div>
    )

  }
}
