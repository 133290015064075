import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/work.css"
import "./styles/work-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class Designlane extends Component { 

  workAnimation = () =>{
    gsap.registerPlugin(ScrollTrigger)
  
    ScrollTrigger.matchMedia({
  
      // all 
      "all": function() {
  
      },
      
      // desktop
      "(min-width: 992px)": function() {
  
          //Main
        gsap.to("main", {
          borderBottomRightRadius:"4vw",
          borderBottomLeftRadius:"4vw",
          scrollTrigger: {
          trigger: "main",
          start: "bottom 100%",
          end: "bottom 20%",
          scrub: true
        }
        });
        
        gsap.set('.work-page-hi', { y: 0});
        gsap.to(".work-page-hi", {
          y: "-10.944vw",
          scrollTrigger: {
            trigger: ".work-page-hi",
            scrub: true
          }
        });
      }, 
  
      // Tab
      "(max-width: 991px) and (min-width: 744px)": function() {
    
      },
  
      // Mobile
      "(max-width: 743px)": function() {
  
      }, 
    
    });
  
  
  
  
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("header").style.top = "0";
      } else {
        document.getElementById("header").style.top = "-150px";
      }
      prevScrollpos = currentScrollPos;
      if (document.documentElement.scrollTop > window.innerHeight * 0.2) {
        document.getElementById("header").className = "headerdown";
      } else {
        document.getElementById("header").className = "";
      }
    }
    
  }
  
  
  componentDidMount(){
      this.workAnimation();
  }


  render() {

    return (
      <div className='work-page designlane'>
        <Header/>
        <main>
        <div className="bg-noise"></div>
            <div className='work-hero container'>
                <div className='hero-content'>
                    <span className='sub-head'>DESIGNLANE</span>
                    <h1>A quality label for <br/>timeless architecture</h1>
                </div>
                <div className='work-hero-img-div'>
                  <img className='work-page-hi img-fluid' width="1300" height="900" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/w-d-1.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>OVERVIEW</span>
                <p>In designing for Design lane Architects, we took into account the wide range of services they offer to both individuals and businesses. Our goal was to create a UI that is both clear and detailed, showcasing their diverse selection of architectural solutions. To achieve this, we implemented intuitive navigation that allows users to easily explore their various offerings.</p>
                <br/>
                <p>The UI design for Design Lane Architects emphasizes their extensive portfolio, which includes everything from residential projects to commercial developments. We crafted a user-friendly interface that showcases their projects through engaging visuals and informative descriptions. Users can effortlessly browse through the different categories, gaining insights into the architectural expertise and capabilities of Designlane Architects.</p>

                <div className='project-details'>
                  <div className='pd-flex'>
                    <h6>Client</h6>
                    <h6>Designlane</h6>
                  </div>
                  <div className='pd-flex'>
                    <h6>Industry</h6>
                    <h6>Architecture</h6>
                  </div>
                  <div className='pd-flex last'>
                    <h6>Services</h6>
                    <h6>Design, Development</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl2 full-tab' width="845.9" height="611" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-2.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl3 half-tab' width="540.75" height="390.59" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-3.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl4 half-mob' width="328" height="664" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-4.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>CHALLENGE</span>
                <p>In designing for Design Lane Architects, we took into account the wide range of services they offer to both individuals and businesses. Our goal was to create a UI that is both clear and detailed, showcasing their diverse selection of architectural solutions. To achieve this, we implemented intuitive navigation that allows users to easily explore their various offerings.</p>
              </div>
            </div>
            <div className='container mock mock2'>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl5 half-mob' width="328" height="664" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-5.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl6 half-mob' width="328" height="664" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-6.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                </div>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl7 full-tab' width="845.9" height="611" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-7.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>SOLUTION</span>
                <p>The experience feels the same across all tablet and mobile devices, without compromising on speed or quality. Sophisticated details and branded micro-interactions make even the mobile version a feast to look at.</p>
              </div>
            </div>
            <div className='container mock mock2'>
              <div className='mock-flex'>
                  <img className='img-fluid mockimg half-mockimgin dl8' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-8.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
                  <img className='img-fluid mockimg half-mockimgin dl9' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-9.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid full-length dl10' width="1260" height="391" src='https://ik.imagekit.io/onceadev/assets/works/designlane/tr:f-auto/designlane-10.png' alt='DESIGN LANE ARCHITECHT ONCEADEV'/>
              </div>
            </div>
            <div className='related'>
                <div className='content'>
                    <span className='sub-head'>NEXT UP</span>
                    <h5>BOOKING VISTA</h5>
                </div>
                <div className='overflow-image'>
                    <img width="620" height="570" className='next-project img-fluid' src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/work---bookingvista.png" alt="onceadev BOOKING VISTA" />
                </div>
                <a className='linka' href='/work/bookingvista'>BOOKING VISTA</a>
            </div>
        </main>
        <Footer/>
        <div className='cursor'>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
      </div>
    )

  }
}
