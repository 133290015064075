import React, { Component } from 'react'

export default class Hero extends Component {



  render() {

    return (
        <section className='container studio-hero'>
          <div className='studio-hero-wrapper'>
            <h1>Digital Prescence <br/>With Maximum <br/>Emotional Impact.</h1>
          </div>
        </section>
    )

  }
}
