import React, { Component } from 'react'
// import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import Hero from '../sub-pages/studio-components/studio-hero'
import Studios2 from './studio-components/studio-sec2'
import { Helmet } from 'react-helmet'



export default class Studio extends Component { 

studioAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

      // all 
      "all": function() {
        // Loader
        gsap.to(".sub-loader", {
          opacity: 0,
          ease: "Power3.easeOut",
          delay: 1.6,
        })


        gsap.from(".sub-loader h4 span", {
          y: 100,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: .3,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })

        gsap.to(".sub-loader h4 span", {
          y: -80,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: 1,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })
      },
      
      // desktop
      "(min-width: 1024px)": function() {

        gsap.to("main", {
          overflow: "hidden",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 120%",
            scrub: true
          }
        });

        gsap.to("main", {
          borderBottomRightRadius:"4vw",
          borderBottomLeftRadius:"4vw",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 100%",
            scrub: true
          }
        });

        gsap.set('.studio-hero h1', { y: 0});
        gsap.to(".studio-hero h1", {
          y: -70,
          scrollTrigger: {
            trigger: ".studio-hero h1",
            start: "top 20%",
            scrub: true
          }
        });


        
      }, 
  
      // mobile
      "(max-width: 1023px)": function() {

      }, 
  
  });




  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerWidth * 0.2) {
      // Hero Section height in vw at 1440 is (73.021vw) which is converted to this code above
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.studioAnimation();
}


  render() {

    return (
      <div className='sub-page studio'>
        <Helmet>
          <title>Onceadev Studio | Where Imagination Meets Innovation</title>
          <meta name="title" content="Onceadev - Building Brands, Driving Growth, Shaping Digital Experiences" />
          <meta name="description"content="Pioneering digital experiences, empowering your brand's growth. Join us on the journey to innovative success with our digital marketing solutions."/>
          <meta property="og:title" content="Studio" />
          <meta name="og:description"content="Pioneering digital experiences, empowering your brand's growth. Join us on the journey to innovative success with our digital marketing solutions."/>
        </Helmet>
        <div className='sub-loader'>
            <h4>
                <span>S</span>
                <span>t</span>
                <span>u</span>
                <span>d</span>
                <span>i</span>
                <span>o</span>
            </h4>
        </div>
        <Header/>
        <main>
          <Hero/>
          <Studios2/>
        </main>
        <Footer/>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
