import React, { Component } from 'react'

export default class Hero extends Component {



  render() {

    return (
        <section className='container contact-hero'>
          <div className='contact-hero-wrapper'>
            <h1>Let’s Reimagine <br/>What’s Possible.</h1>
          </div>
        </section>
    )

  }
}
