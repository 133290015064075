import React, { Component } from 'react'
// import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import Hero from './work-components/work-hero'
import Works2 from './work-components/work-sec2'
import { Helmet } from 'react-helmet'



export default class Work extends Component { 

workAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

      // all 
      "all": function() {
        // Loader
        gsap.to(".sub-loader", {
          opacity: 0,
          ease: "Power3.easeOut",
          delay: 1.6,
        })

        gsap.from(".sub-loader h4 span", {
          y: 100,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: .3,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })

        gsap.to(".sub-loader h4 span", {
          y: -80,
          rotateZ: 10,
          ease: "Power3.easeOut",
          delay: 1,
          duration: 0.5,
          stagger: {
            amount: 0.2
          }
        })

        gsap.to("main", {
          overflow: "hidden",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 120%",
            scrub: true
          }
        });

        gsap.to("main", {
          borderBottomRightRadius:"4vw",
          borderBottomLeftRadius:"4vw",
          scrollTrigger: {
            trigger: "main",
            start: "bottom 100%",
            scrub: true
          }
        });

        gsap.set('.services-hero h1', { y: 0});
        gsap.to(".services-hero h1", {
          y: -70,
          scrollTrigger: {
            trigger: ".services-hero h1",
            start: "top 20%",
            scrub: true
          }
        });
      },
      
      // desktop
      "(min-width: 992px)": function() {

        
      }, 

      //Tab
      "(max-width: 991px) and (min-width: 744px)": function() {


      },
  
      // mobile
      "(max-width: 743px)": function() {

      }, 
  
  });

//   $('.contact-form label, .contact-form input, .contact-form textarea').hover(function() {
//     $('.circle').css('width', '0');
//     $('.circle').css('height', '0');
//   }, function() {
//     $('.circle').css('width', '');
//     $('.circle').css('height', '');
//   });


  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerWidth * 0.2) {
      // Hero Section height in vw at 1440 is (73.021vw) which is converted to this code above
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.workAnimation();
}


  render() {

    return (
      <div className='sub-page works'>
        <Helmet>
          <title>Onceadev Works | Crafting Experiences That Inspire</title>
          <meta name="title" content="Onceadev Works | Crafting Experiences That Inspire" />
          <meta name="description"content="Step into Onceadev's world of digital excellence. Experience our portfolio of design masterpieces and the stories behind each success."/>
          <meta property="og:title" content="Work" />
          <meta name="og:description"content="Step into Onceadev's world of digital excellence. Experience our portfolio of design masterpieces and the stories behind each success."/>
        </Helmet>
        <div className='sub-loader'>
            <h4>
                <span>W</span>
                <span>o</span>
                <span>r</span>
                <span>k</span>
            </h4>
        </div>
        <Header/>
        <main>
        <Hero/>
        <Works2/>

        </main>
        <Footer/>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
