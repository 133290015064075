import React, { Component } from 'react'


export default class WorkLead extends Component {

  render() {

    return (
        <div className='work-lead'>
            <div className='work-lead-cont'>
                <h3 className='work-lead-h3'>
                <div className='line'>
                  <span>We design for brands,</span>
                </div>
                <div className='line'>
                  <span>we work for people.</span>
                </div>
                </h3>
                <img width="671" height="914" src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/hand.png' className='img-fluid hand work-lead-img' alt='Hand decoration onceadev we design for brands'/>
                <p className='work-lead-p'>Our team is passionate about creating <br className='mob-br'/><br className='desk-br'/>change, <br className='tab-br'/>not only in how branding is <br className='mob-br'/>done <br className='desk-br'/>but also <br className='tab-br'/>socially, sustainable, <br className='mob-br'/>and responsibly</p>
                <h4 className='work-lead-h4'>Better just see for yourself</h4>
            </div>
        </div>
    )

  }
}
