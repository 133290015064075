import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/work.css"
import "./styles/work-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class Coinguard extends Component { 

workAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

    // all 
    "all": function() {

    },
    
    // desktop
    "(min-width: 992px)": function() {

        //Main
      gsap.to("main", {
        borderBottomRightRadius:"4vw",
        borderBottomLeftRadius:"4vw",
        scrollTrigger: {
        trigger: "main",
        start: "bottom 100%",
        end: "bottom 20%",
        scrub: true
      }
      });

      gsap.set('.work-page-hi', { y: 0});
      gsap.to(".work-page-hi", {
        y: "-10.944vw",
        scrollTrigger: {
          trigger: ".work-page-hi",
          scrub: true
        }
      });
    }, 

    // Tab
    "(max-width: 991px) and (min-width: 744px)": function() {
  
    },

    // Mobile
    "(max-width: 743px)": function() {

    }, 
  
  });




  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerHeight * 0.2) {
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.workAnimation();
}


  render() {

    return (
      <div className='work-page coinguard'>
        <Header/>
        <main>
        <div className="bg-noise"></div>
            <div className='work-hero container'>
                <div className='hero-content'>
                    <span className='sub-head'>COINGUARD WALLET</span>
                    <h1>Unlock a new level of trust and <br className='desk-br'/>control in cryptocurrencies</h1>
                </div>
                <div className='work-hero-img-div'>
                  <img className='work-page-hi img-fluid' width="1300" height="900" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-1.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>OVERVIEW</span>
                <p>Coinguard is a cutting-edge crypto wallet designed to provide users with a secure and user-friendly platform for managing their digital assets. In a rapidly evolving cryptocurrency landscape, Coinguard stands out by prioritizing the protection of user funds and ensuring a seamless and intuitive experience.</p>
                <br/>
                <p>By leveraging advanced encryption techniques and a user-centric design, Coinguard aims to empower individuals to confidently engage in cryptocurrency transactions, track their portfolio, and explore new investment opportunities.</p>

                <div className='project-details'>
                  <div className='pd-flex'>
                    <h6>Client</h6>
                    <h6>Coinguard</h6>
                  </div>
                  <div className='pd-flex'>
                    <h6>Industry</h6>
                    <h6>Web 3, Blockchain</h6>
                  </div>
                  <div className='pd-flex last'>
                    <h6>Services</h6>
                    <h6>UX Research, UI Design</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl2 threemorl' width="1045" height="662.77" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-2.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl3 widget' width="447.5" height="450" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-3.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg l1'>
                  <img className='img-fluid dl4 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-4.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>CHALLENGE</span>
                <p>Creating a top-tier crypto wallet posed the challenge of balancing robust security with an appealing and user-friendly design. Our goal was to ensure the safety of user funds while delivering a visually engaging and intuitive experience. This required innovative thinking and a deep understanding of user preferences to achieve an optimal solution.</p>
              </div>
            </div>
            <div className='container mock mock2'>
            <div className='mock-flex'>
                  <img className='img-fluid mockimg half-mockimgin dl8' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-5.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                  <img className='img-fluid mockimg half-mockimgin dl9' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-6.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl7 threemorl' width="1045" height="662.77" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-7.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>SOLUTION</span>
                <p>Our design team collaborated with user experience experts to create a visually captivating and modern interface. We embraced clean and minimalist aesthetics, allowing users to navigate the app's features with ease. Extensive user testing and feedback guided iterative improvements to the app's design and user experience.</p>
                <br/>
                <p>By combining visually appealing design elements with intuitive interactions, Coinguard offers a secure platform where users can effortlessly manage their crypto portfolio, access real-time market data, and execute transactions confidently.</p>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid threemorl' width="1045" height="662" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard--8.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg l2'>
                  <img className='img-fluid dl5 half-mob' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard-9.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg l3'>
                  <img className='img-fluid dl6 half-mob' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/coinguard/tr:f-auto/coinguard--10.png' alt='COINGUARD CRYPTO WALLET ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='related'>
                <div className='content'>
                    <span className='sub-head'>NEXT UP</span>
                    <h5>DESIGNLANE</h5>
                </div>
                <div className='overflow-image'>
                    <img width="620" height="570" className='next-project img-fluid' src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-designlane.png" alt="onceadev DESIGNLANE" />
                </div>
                <a className='linka' href='/work/designlane'>designlane</a>
            </div>
        </main>
        <Footer/>
        <div className='cursor'>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
      </div>
    )

  }
}
