import React, { Component } from 'react';
import StudioFAQ from './studio-faq';
// import StudioC from './studio-career';
import StudioHWD from './studio-hwd';
import Studiovalues from './studio-values';
import StudioWWA from './studio-wwa';


export default class Studios2 extends Component {

  render() {

    return (
      <section className="studio-section2">
        <div className="bg-noise"></div>
        <div className='container'>
              <hr></hr>
        </div>
        <div className="sub-s2">
            <StudioWWA/>
            <StudioHWD/>
            <Studiovalues/>
            <StudioFAQ/>
            {/* <StudioC/> */}
        </div>
      </section>
    );
  }
}
