import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './styles/index.css'
import Home from './home/home';
import Contact from './sub-pages/contact';
import Services from './sub-pages/services';
import Lost from './sub-pages/404';
import Thanks from './sub-pages/thankyou';
import Studio from './sub-pages/studio';
import Designlane from './works/designlane';
import Comingsoon from './sub-pages/comingsoon';
import Work from './sub-pages/work';
import Coinguard from './works/coinguard';
import Yugadecors from './works/yugadecors';
import BookingVista from './works/bookingvista';


const root = ReactDOM.createRoot(document.getElementById('onceadev'));
root.render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/studio' element={<Studio />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='/services' element={<Services />} />
      <Route exact path='/work' element={<Work />} />

      {/* Extras */}
      <Route exact path='*' element={<Lost />} />
      <Route exact path='/thanks' element={<Thanks />} />
      <Route exact path='/soon' element={<Comingsoon />} />

      {/* Work */}
      <Route exact path='/work/designlane' element={<Designlane />} />
      <Route exact path='/work/coinguard' element={<Coinguard />} />
      <Route exact path='/work/yugadecors' element={<Yugadecors />} />
      <Route exact path='/work/bookingvista' element={<BookingVista />} />

    </Routes>
  </BrowserRouter>
);