import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import "./styles/work.css"
import "./styles/work-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class Yugadecors extends Component { 

workAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

    // all 
    "all": function() {

    },
    
    // desktop
    "(min-width: 992px)": function() {

        //Main
      gsap.to("main", {
        borderBottomRightRadius:"4vw",
        borderBottomLeftRadius:"4vw",
        scrollTrigger: {
        trigger: "main",
        start: "bottom 100%",
        end: "bottom 20%",
        scrub: true
      }
      });

      gsap.set('.work-page-hi', { y: 0});
      gsap.to(".work-page-hi", {
        y: "-10.944vw",
        scrollTrigger: {
          trigger: ".work-page-hi",
          scrub: true
        }
      });
    }, 

    // Tab
    "(max-width: 991px) and (min-width: 744px)": function() {
  
    },

    // Mobile
    "(max-width: 743px)": function() {

    }, 
  
  });




  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerHeight * 0.2) {
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
  
}


componentDidMount(){
    this.workAnimation();
}


  render() {

    return (
      <div className='work-page yugadecors'>
        <Header/>
        <main>
        <div className="bg-noise"></div>
            <div className='work-hero container'>
                <div className='hero-content'>
                    <span className='sub-head'>YUGA DECORS</span>
                    <h1>Elevating Spaces With <br className='desk-br'/>Comfort & Elegance.</h1>
                </div>
                <div className='work-hero-img-div'>
                  <img className='work-page-hi img-fluid' width="1300" height="900" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors-1.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
                </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>OVERVIEW</span>
                <p>Yuga Decors is a sophisticated furniture e-commerce website that aims to elevate living spaces with comfort and elegance. Offering a wide range of premium-quality furniture and decor pieces, the platform provides customers with an immersive shopping experience.</p>
                <br/>
                <p>It is designed for individuals who seek to create stylish and inviting environments in their homes or offices. Yuga Decors strives to be the go-to destination for those who value both aesthetics and functionality in their furniture choices.</p>

                <div className='project-details'>
                  <div className='pd-flex'>
                    <h6>Client</h6>
                    <h6>Yuga Decors</h6>
                  </div>
                  <div className='pd-flex'>
                    <h6>Industry</h6>
                    <h6>Furniture</h6>
                  </div>
                  <div className='pd-flex last'>
                    <h6>Services</h6>
                    <h6>Branding, Design, Development</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className='container mock mock1'>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl2 big d-none d-lg-block' width="1260" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors2.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
                <img className='img-fluid dl2 big1 d-block d-lg-none' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors2m.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>

              </div>
              <div className='mock-flex'>
                <div className='mockimg half-mockimg'>
                  <img className='img-fluid dl3 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors3.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
                </div>
                <div className='mockimg half-mockimg l1'>
                  <img className='img-fluid dl4 mobile1' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors4.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
                </div>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>CHALLENGE</span>
                <p>The primary challenge in creating Yuga Decors was to stand out in the highly competitive e-commerce market and establish the brand as a reliable source for premium furniture. Additionally, ensuring a seamless and personalized shopping experience was essential to differentiate Yuga Decors from its competitors.</p>
                <br/>
                <p>Addressing the logistics of handling diverse furniture items, managing inventory, and optimizing the website's performance to handle high traffic volumes posed further challenges.</p>
              </div>
            </div>
            <div className='container mock mock2'>
            <div className='mock-flex'>
                  <img className='img-fluid mockimg half-mockimgin dl8' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors5.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
                  <img className='img-fluid mockimg half-mockimgin dl9' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors6.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid dl7 big' width="1260" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors7.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
              </div>
            </div>
            <div className='container'>
              <div className='work-inner-container'>
                <span className='sub-head'>SOLUTION</span>
                <p>We adopted a comprehensive solution that focused on enhancing the overall customer experience. We worked extensively on the website's design and navigation, emphasizing user-friendliness and aesthetics. By creating curated collections that catered to different tastes and interior styles.</p>
                <br/>
                <p>To optimize inventory management and logistics, we integrated an advanced backend system that streamlined order processing and provided real-time inventory updates. These improvements positioned Yuga Decors as the trusted destination for premium furniture and decor, embracing the motto of "Elevating Spaces with Comfort & Elegance."</p>
              </div>
            </div>
            <div className='container mock mock2'>
              <div className='mock-flex'>
                  <img className='img-fluid mockimg half-mockimgin dl8' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors8.png' alt='YUGA DECORS ECOMMERCE ONCEADEV'/>
                  <img className='img-fluid mockimg half-mockimgin dl9' width="620" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors-9.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
              </div>
              <div className='mockimg full-mockimg'>
                <img className='img-fluid big dl10' width="1260" height="720" src='https://ik.imagekit.io/onceadev/assets/works/yugadecors/tr:f-auto/yugadecors10.png' alt='YUGA DECORS ECOMMERCE  ONCEADEV'/>
              </div>
            </div>
            <div className='related'>
                <div className='content'>
                    <span className='sub-head'>NEXT UP</span>
                    <h5>COINGUARD</h5>
                </div>
                <div className='overflow-image'>
                    <img width="620" height="570" className='next-project img-fluid' src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/work-coinguard.png" alt="onceadev Coinguard" />
                </div>
                <a className='linka' href='/work/coinguard'>Coinguard</a>
            </div>
        </main>
        <Footer/>
        <div className='cursor'>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
      </div>
    )

  }
}
