import React, { Component } from 'react'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'



export default class Thanks extends Component {

  render() {

    return (
      <div className='extra-page thanks'>
        <div className='d-none'><Header/></div>
        <h3>Your questions <br/>have been jotted</h3>
        <p>We’ve received your questions and are <br className='mob-br'/>ready to dig in. <br className='tab-br'/><br className='desk-br'/>Meanwhile, feel free to schedule <br className='mob-br'/>meeting or learn <br className='tab-br'/><br className='desk-br'/>more about our company.</p>
        <div className='extra-flex'>
        <a href='https://calendly.com/onceadev/15min' target="_blank" rel="noreferrer" className='button pb' >
            <span data-text="Schedule a meeting">
                <i>Schedule a meeting</i>
            </span>
        </a>
        <a href='/' className='button sb' >
            <span data-text="Back to homepage">
                <i>Back to homepage</i>
            </span>
        </a>
        </div>
        <div className='d-none'><Footer/></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
