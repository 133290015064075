import React, { Component } from 'react';


export default class Servicess2 extends Component {

  render() {

    return (
      <section className="container services-section2">
        <div className="bg-noise"></div>
        <div className="sub-s2">
            <div className='s-all s-wwa'>
                <span className='s-nu sub-heading'>01</span>
                <h3>Website & Application</h3>
                <h5>We create unique websites, web apps, and e-commerce solutions that <br className='desk-br'/>blend strategy, branding, and e-commerce to provide engaging experiences <br className='desk-br'/>and drive conversions, tailored to your project's specific needs.</h5>
                <div className='s-rows'>
                    <div className='s-row s-row-left'>
                        <div>
                            <p>Wireframing</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Interactive design</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>UX & prototyping</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Research & analysis</p>
                            <hr></hr>
                        </div>
                    </div>
                    <div className='s-row s-row-right'>
                        <div>
                            <p>Custom website development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>E-commerce development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Application Design and Development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Website optimization (Web audits)</p>
                            <hr></hr>
                        </div>
                    </div>
                </div>
                <a href='/contact' className='button' >
                    <span data-text="Start Your project">
                        <i>Start Your project</i>
                    </span>
                </a>
            </div>
            <div className='s-all s-b-m'>
                <span className='s-nu sub-heading'>02</span>
                <h3>Branding & Marketing</h3>
                <h5>By combining thorough market research, competitive analysis, and engaging <br className='desk-br'/>content, we excel in crafting strategic brand messaging, positioning, and targeting. <br className='desk-br'/>We design and develop calculated strategies to amplify your brand's message across <br className='desk-br'/>all audiences, be it a digital platform, a marketing campaign, or a live event.</h5>
                <div className='s-rows'>
                    <div className='s-row s-row-left'>
                        <div>
                            <p>Brand identity</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Brand experiences</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Market research</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Online reputation management</p>
                            <hr></hr>
                        </div>
                    </div>
                    <div className='s-row s-row-right'>
                        <div>
                            <p>Content Solutions</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Launch Campaigns and Activation.</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Engagement Strategy</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Social media campaigns</p>
                            <hr></hr>
                        </div>
                    </div>
                </div>
                <a href='/contact' className='button' >
                    <span data-text="Start Your project">
                        <i>Start Your project</i>
                    </span>
                </a>
            </div>
            <div className='s-all s-web3'>
                <span className='s-nu sub-heading'>03</span>
                <h3>Web3</h3>
                <h5>We provide cutting-edge web3 services to help organizations take advantage of the latest <br className='desk-br'/>advancements in decentralized technology. Our team of web3 designers, smart contract <br className='desk-br'/>developers, and decentralized applications (dApps) specialists will work with <br className='desk-br'/>you to leverage these technologies to launch innovative solutions.</h5>
                <div className='s-rows'>
                    <div className='s-row s-row-left'>
                        <div>
                            <p>Custom web3 design & development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>dApps development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Smart contract development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>DeFi development</p>
                            <hr></hr>
                        </div>
                    </div>
                    <div className='s-row s-row-right'>
                        <div>
                            <p>NFT conceptualization</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>NFT rarity management</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Metaverse development</p>
                            <hr></hr>
                        </div>
                        <div>
                            <p>Web 3 strategy</p>
                            <hr></hr>
                        </div>
                    </div>
                </div>
                <a href='/contact' className='button' >
                    <span data-text="Start Your project">
                        <i>Start Your project</i>
                    </span>
                </a>
            </div>
        </div>
      </section>
    );
  }
}
