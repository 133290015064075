import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Splitting from 'splitting';

import "./styles/home.css"
import "./styles/home-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import Hero from './components/hero'
import Whatwedo from './components/whatwedo'
import About from './components/about'
import WorkLead from './components/worklead'
import TestimonialLead from './components/testimoniallead'
import Work from './components/work'
import Testimonial from './components/testimonial'
import Loader from './components/loader';
import { Helmet } from 'react-helmet';



export default class Home extends Component { 

homeAnimation = () =>{
  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({

    // all 
    "all": function() {

      //Main
      gsap.to("main", {
        borderBottomRightRadius:"4vw",
        borderBottomLeftRadius:"4vw",
        scrollTrigger: {
          trigger: "main",
          start: "bottom 100%",
          end: "bottom 20%",
          scrub: true
        }
      });

      // Loader
      gsap.to(".home-loader", {
        opacity: 0,
        ease: "Power3.easeOut",
        delay: 1.3,
      })


      gsap.from(".home-loader h4 span", {
        y: 100,
        rotateZ: 10,
        ease: "Power3.easeOut",
        delay: .3,
        duration: 0.5,
        stagger: {
          amount: 0.2
        }
      })

      gsap.to(".home-loader h4 span", {
        y: -80,
        rotateZ: 10,
        ease: "Power3.easeOut",
        delay: 1,
        duration: 0.5,
        stagger: {
          amount: 0.2
        }
      })

      //Hero
      gsap.from("h1 span", 0.9, {
        y: 400,
        rotateZ: 10,
        ease: "Power3.easeOut",
        delay: 1.5,
        stagger: {
          amount: 0.3
        }
      })
      

      //About 
      gsap.set('.home-about span', { opacity: 0.3});
      gsap.to('.home-about span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.home-about span',
          end: "top -60vh",
          scrub: true
        }
      });

      //What we do

      gsap.set('.whatwedo .heading h3', {opacity:0, y: 140});
      gsap.to('.whatwedo .heading h3', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedo .heading h3',
          start: "top 100%"
        }
      });

      gsap.set('.whatwedo .heading p', {opacity:0, y: 140});
      gsap.to('.whatwedo .heading p', {
        opacity: 1,
        y: 0,
        delay: 0.2,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedo .heading p',
          start: "top 100%"
        }
      });

      
      //Work Lead

      gsap.set('.work-lead-h3 span', {opacity:0, y: 140});
      gsap.to('.work-lead-h3 span', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work-lead-h3 span',
          start: "top 90%",
        }
      });

      gsap.set('.work-lead img', {opacity:0, y: 140});
      gsap.to('.work-lead img', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work-lead img',
          start: "top 80%",
        }
      });

      gsap.set('.work-lead p', {opacity:0, y: 140});
      gsap.to('.work-lead p', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work-lead p',
          start: "top 80%",
        }
      });

      gsap.set('.work-lead h4', {opacity:0, y: 140});
      gsap.to('.work-lead h4', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work-lead h4',
          start: "top 80%",
        }
      });

      //Work

      gsap.set('.work .heading h3', {opacity:0, y: 140});
      gsap.to('.work .heading h3', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .heading h3',
          start: "top 100%"
        }
      });

      gsap.set('.work .heading p', {opacity:0, y: 140});
      gsap.to('.work .heading p', {
        opacity: 1,
        y: 0,
        delay: 0.2,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .heading p',
          start: "top 100%"
        }
      });

      gsap.set('.work .project1', {opacity:0, yPercent: 40});
      gsap.to('.work .project1', {
        opacity:1,
        yPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .project1',
          start: "top 100%"
        }
      });

      gsap.set('.work .project2', {opacity:0, yPercent: 40});
      gsap.to('.work .project2', {
        opacity:1,
        yPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .project2',
          start: "top 100%"
        }
      });

      gsap.set('.work .project3', {opacity:0, yPercent: 40});
      gsap.to('.work .project3', {
        opacity:1,
        yPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .project3',
          start: "top 100%"
        }
      });

      gsap.set('.work .project4', {opacity:0, yPercent: 40});
      gsap.to('.work .project4', {
        opacity:1,
        yPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .project4',
          start: "top 100%"
        }
      });


      gsap.set('.work .work-cta', {opacity:0, y: 140});
      gsap.to('.work .work-cta', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.work .work-cta',
          start: "top 100%"
        }
      });


      //Testimonial
      gsap.set('.testimonials .heading h3', {opacity:0, y: 140});
      gsap.to('.testimonials .heading h3', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonials .heading h3',
          start: "top 100%"
        }
      });

      gsap.set('.testimonials .heading p', {opacity:0, y: 140});
      gsap.to('.testimonials .heading p', {
        opacity: 1,
        y: 0,
        delay: 0.2,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonials .heading p',
          start: "top 100%"
        }
      });


      
    },
    
    // desktop
    "(min-width: 992px)": function() {

      //Main
      //moved to all


      //Hero
      gsap.set(".marquee-e", {opacity:0, y: 200});
      gsap.to(".marquee-e", {
        opacity:1,
        y: 0,
        scrollTrigger: {
          trigger: ".marquee-e",
          start: "top 90%",
        }
      });

      gsap.set(".third p", {opacity:0, y: 200});
      gsap.to(".third p", {
        opacity:1,
        y: 0,
        scrollTrigger: {
          trigger: ".third p",
          start: "top 90%",
        }
      });

      gsap.set(".third .button", {opacity:0, y: 200});
      gsap.to(".third .button", {
        opacity:1,
        y: 0,
        scrollTrigger: {
          trigger: ".third p",
          start: "top 90%",
        }
      });

      gsap.to(".home-hero-bg", {
        paddingLeft:"1vw",
        paddingRight:"1vw",
        borderBottomRightRadius:"4vw",
        borderBottomLeftRadius:"4vw",
        scrollTrigger: {
          trigger: ".home-hero-bg",
          start: "top -10%",
          scrub: true
        }
      });

      //About 
      //moved to all

      //Work Lead
      //moved to all

      //Work
      //moved to all


      //TestimonialLead
      gsap.set('.testimonial-lead span', { opacity: 0.3});
      gsap.to('.testimonial-lead span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-lead span',
          end: "top -100%",
          scrub: true
        }
      });

      //Testimonial
      //moved to all


      //Testimonial Each
      gsap.set('.testimonial-each .t-one span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-one span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-one span',
          end: "top -75vh",
          scrub: true
        }
      });

      gsap.set('.testimonial-each .t-two span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-two span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-two span',
          end: "top -40vh",
          scrub: true
        }
      });

      gsap.set('.testimonial-each .t-three span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-three span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-three span',
          end: "top -40vh",
          scrub: true
        }
      });

    }, 

    // Tab
    "(max-width: 991px) and (min-width: 744px)": function() {

      //Hero
      gsap.set(".marquee-e", {opacity:0, y: 200});
      gsap.to(".marquee-e", {
        opacity:1,
        y: 0,
        delay: 2,
      });

      gsap.set(".third p", {opacity:0, y: 200});
      gsap.to(".third p", {
        opacity:1,
        y: 0,
        delay: 2.5,
      });

      gsap.set(".third .button", {opacity:0, y: 200});
      gsap.to(".third .button", {
        opacity:1,
        y: 0,
        delay: 2.5,
      });

      //TestimonialLead
      gsap.set('.testimonial-lead span', { opacity: 0.3});
      gsap.to('.testimonial-lead span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-lead span',
          end: "top -100vh",
          scrub: true
        }
      });

      //What we do
      gsap.set('.whatwedotm .w-card1', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card1', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card1',
          start: "top 100%"
        }
      });
      gsap.set('.whatwedotm .w-card2', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card2', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card2',
          start: "top 100%"
        }
      });
      gsap.set('.whatwedotm .w-card3', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card3', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card3',
          start: "top 100%"
        }
      });


      //Testimonial Each
      gsap.set('.testimonial-each .t-one span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-one span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-one span',
          end: "top -75vh",
          scrub: true
        }
      });

      gsap.set('.testimonial-each .t-two span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-two span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-two span',
          end: "top -40vh",
          scrub: true
        }
      });

      gsap.set('.testimonial-each .t-three span', { opacity: 0.3});
      gsap.to('.testimonial-each .t-three span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each .t-three span',
          end: "top -40vh",
          scrub: true
        }
      });
  
    },

    // Mobile
    "(max-width: 743px)": function() {

      //Hero
      gsap.set(".marquee-e", {opacity:0, y: 200});
      gsap.to(".marquee-e", {
        opacity:1,
        y: 0,
        delay: 2,
      });

      gsap.set(".third p", {opacity:0, y: 200});
      gsap.to(".third p", {
        opacity:1,
        y: 0,
        delay: 2.5,
      });

      gsap.set(".third .button", {opacity:0, y: 200});
      gsap.to(".third .button", {
        opacity:1,
        y: 0,
        delay: 2.5,
      });


      //What we do
      gsap.set('.whatwedotm .w-card1', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card1', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card1',
          start: "top 100%"
        }
      });
      gsap.set('.whatwedotm .w-card2', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card2', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card2',
          start: "top 100%"
        }
      });
      gsap.set('.whatwedotm .w-card3', {opacity:0, xPercent: -100});
      gsap.to('.whatwedotm .w-card3', {
        opacity:1,
        xPercent: 0,
        duration: 1,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.whatwedotm .w-card3',
          start: "top 100%"
        }
      });


      //TestimonialLead
      gsap.set('.testimonial-lead span', { opacity: 0.3});
      gsap.to('.testimonial-lead span', {
        opacity: 1,
        stagger:{
          from:"start",
          each:0.2
        },
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-lead span',
          end: "top -100vh",
          scrub: true
        }
      });

      //Testimonial Each
      gsap.set('.testimonial-each1', { opacity:0, y: 140});
      gsap.to('.testimonial-each1', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each1',
          start: "top 100%"
        }
      });

      gsap.set('.testimonial-each2', { opacity:0, y: 140});
      gsap.to('.testimonial-each2', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each2',
          start: "top 100%"
        }
      });

      gsap.set('.testimonial-each3', { opacity:0, y: 140});
      gsap.to('.testimonial-each3', {
        opacity: 1,
        y: 0,
        ease: "Power3.easeOut",
        scrollTrigger: {
          trigger: '.testimonial-each3',
          start: "top 100%"
        }
      });




    }, 
      
  
  });



  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
    } else {
      document.getElementById("header").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
    if (document.documentElement.scrollTop > window.innerWidth * 0.71021) {
      // Hero Section height in vw at 1440 is (73.021vw) which is converted to this code above
      document.getElementById("header").className = "headerdown";
    } else {
      document.getElementById("header").className = "";
    }
  }
}


componentDidMount(){
    Splitting();
    this.homeAnimation();
}


  render() {

    return (
      <div className='home'>
        <Helmet>
          <title>Onceadev - Building Brands, Driving Growth, Shaping Digital Experiences</title>
          <meta name="title" content="Onceadev - Building Brands, Driving Growth, Shaping Digital Experiences" />
          <meta name="description"content="Unlock the potential of your brand with Onceadev. We are pioneers in building exceptional brands, driving business growth, and shaping innovative digital experiences. Connect with our expert team today."/>
        </Helmet>
        <Header/>
        <main>
        <div className="bg-noise"></div>
          <Hero/>
          <About />
          <Whatwedo />
          <WorkLead />
          <Work/>
          <TestimonialLead/>
          <Testimonial/>
        </main>
        <Loader/>
        <Footer/>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
