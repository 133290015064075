import React, { Component } from 'react'


export default class Whatwedo extends Component {


  render() {

    return (
        <div className='whatwedo'>
            <div className='container heading'>
                <div className='row'>
                    <div className='col-lg-6 left'>
                        <h3>What we do</h3>
                    </div>
                    <div className='col-lg-6 right desk-br'>
                        <p>Take a look at what we can do for you</p>
                    </div>
                </div>
            </div>
            <div className='row desk-br whatwedotd'>
                <div className='col-lg-3 inner-block one desk' data-text="Plip Plop">
                    <div className='wwd-bg'></div>
                    <a href='/services'>Services page</a>
                    <h4>Web / App & <br className='desk-br'/>Product <br className='desk-br'/>Development</h4>
                </div>
                <div className='col-lg-3 inner-block two'>
                    <div className='wwd-bg'></div>
                    <a href='/services'>Services page</a>
                    <h4>Digital <br className='desk-br'/>Strategy</h4>
                </div>
                <div className='col-lg-3 inner-block three'>
                    <div className='wwd-bg'></div>
                    <a href='/services'>Services page</a>
                    <h4>Web3 / <br className='desk-br'/>Blockchain <br className='desk-br'/>Development</h4>
                </div>
                <div className='col-lg-3 inner-block-l'>
                    <img width='300' height='300' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/whatwedo.svg' className='img-fluid rotate' alt='onceadev what we do'/>
                    <img width='360' height='700' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/wwd-1_v9hPWU4Vg.png' className='img-fluid plop web' alt='onceadev web design and development'/>
                    <img width='360' height='700' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/wwd-2_uuEeuOVZJ.png' className='img-fluid plop digital' alt='onceadev digital marketing'/>
                    <img width='360' height='700' src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/wwd-3_7a00CWt4Pc.png' className='img-fluid plop web3' alt='onceadev web3 nfts blockchain'/>
                </div>
            </div>

            <div className='whatwedotm'>
                <div className='w-card w-card1'>
                    <a href='/services'>Services page</a>
                    <h4>Web /App & <br/>Product Development</h4>
                    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="48" height="48" rx="24" fill="black"/>
                        <g clipPath="url(#clip0_344_4013)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M28.8513 18.496L33.4335 23.0782C33.7147 23.3594 33.7147 23.8153 33.4335 24.0965L28.8513 28.6787C28.5701 28.9599 28.1142 28.9599 27.833 28.6787C27.5519 28.3975 27.5519 27.9416 27.833 27.6604L31.1861 24.3074H14.5078V22.8674H31.1861L27.833 19.5143C27.5519 19.2331 27.5519 18.7772 27.833 18.496C28.1142 18.2149 28.5701 18.2149 28.8513 18.496Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_344_4013">
                        <rect width="19.1742" height="12.11" fill="black" transform="translate(14.5078 17.5312)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className='w-card w-card2'>
                    <a href='/services'>Services page</a>
                    <h4>Digital <br/>Strategy</h4>
                    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="48" height="48" rx="24" fill="black"/>
                        <g clipPath="url(#clip0_344_4013)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M28.8513 18.496L33.4335 23.0782C33.7147 23.3594 33.7147 23.8153 33.4335 24.0965L28.8513 28.6787C28.5701 28.9599 28.1142 28.9599 27.833 28.6787C27.5519 28.3975 27.5519 27.9416 27.833 27.6604L31.1861 24.3074H14.5078V22.8674H31.1861L27.833 19.5143C27.5519 19.2331 27.5519 18.7772 27.833 18.496C28.1142 18.2149 28.5701 18.2149 28.8513 18.496Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_344_4013">
                        <rect width="19.1742" height="12.11" fill="black" transform="translate(14.5078 17.5312)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className='w-card w-card3'>
                    <a href='/services'>Services page</a>
                    <h4>Web3 / Metaverse <br/>Development</h4>
                    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="48" height="48" rx="24" fill="black"/>
                        <g clipPath="url(#clip0_344_4013)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M28.8513 18.496L33.4335 23.0782C33.7147 23.3594 33.7147 23.8153 33.4335 24.0965L28.8513 28.6787C28.5701 28.9599 28.1142 28.9599 27.833 28.6787C27.5519 28.3975 27.5519 27.9416 27.833 27.6604L31.1861 24.3074H14.5078V22.8674H31.1861L27.833 19.5143C27.5519 19.2331 27.5519 18.7772 27.833 18.496C28.1142 18.2149 28.5701 18.2149 28.8513 18.496Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_344_4013">
                        <rect width="19.1742" height="12.11" fill="black" transform="translate(14.5078 17.5312)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    )

  }
}
