import React, { Component} from 'react'


export default class StudioWWA extends Component {

  render() {

    return (
        <div className='studio-wwa'>
            <div className='container'>
                <span className='sub-heading'>who we are</span>
                <div className='wwa-content'>
                    <div className='wwa-c-left'>
                        <h2>Creatives committed to <br/>design with a purpose</h2>
                    </div>
                    <div className='wwa-c-right'>
                        <p>We are a compact, senior team of creative thinkers, <br className='desk-br'/>strategists, <br className='tab-br'/>designers and developers, who strive to <br className='desk-br'/>develop long-term <br className='tab-br'/>relationships with our clients, <br className='desk-br'/>aiding continual growth <br className='tab-br'/>and ongoing success.</p>
                    </div>
                </div>
            </div>

            <div className="wwa-slider">
                <div className="images-container">
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l2_Qis2hlWZZ.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p1_5TPDbnFBS.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l1.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p2_1fI5dIoxY.png" alt="decoration-images" />
                    {/* Duplicate the images */}
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l3_wtYCz0ekd.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p3_7O-YuDxII.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l2_Qis2hlWZZ.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p1_5TPDbnFBS.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l1.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p2_1fI5dIoxY.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l3_wtYCz0ekd.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p3_7O-YuDxII.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l2_Qis2hlWZZ.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p1_5TPDbnFBS.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l1.png" alt="decoration-images" />
                    <img className="image image2" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-p2_1fI5dIoxY.png" alt="decoration-images" />
                    <img className="image image1" src="https://ik.imagekit.io/onceadev/assets/tr:f-auto/about-images-l3_wtYCz0ekd.png" alt="decoration-images" />
\


                </div>
            </div>


        </div>
    )

  }
}
