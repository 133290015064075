import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import ContactC from './contact-career';


export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      companyWebsite: '',
      services: [],
      // budget: '',
      message: '',
      errors: {},
      submitted: false, // Added state to track form submission
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleServiceChange = (e) => {
    const { value, checked } = e.target;
    const { services } = this.state;

    if (checked && !services.includes(value)) {
      this.setState((prevState) => ({
        services: [...prevState.services, value],
      }));
    } else if (!checked && services.includes(value)) {
      this.setState((prevState) => ({
        services: prevState.services.filter((service) => service !== value),
      }));
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      try {
        const { name, email, phoneNumber, companyName, companyWebsite, services, 
               // budget, 
               message } = this.state;

        // Create the template parameters for EmailJS
        const templateParams = {
          name,
          email,
          phoneNumber,
          companyName,
          companyWebsite,
          services: services.join(', '),
          // budget,
          message,
        };

        // Update the state to indicate form submission
        this.setState({ submitted: true });

        // Change the button text to "Sending"
        const submitButton = document.getElementById('submit-button');
        if (submitButton) {
          submitButton.innerText = 'Sending...';
        }

        // Send the email using EmailJS
        await emailjs.send('service_aav5vyu', 'template_ng4c0q8', templateParams, 'OB-TA-KEW4WU6S5xM');

        // Update the state to indicate form submission and trigger redirection
        this.setState({ submitted: true });
      } catch (error) {
        console.log('Error sending the form:', error);
      }
    }
  };

  validateForm = () => {
    const { name, email, phoneNumber, services, 
           // budget 
          } = this.state;
    let isValid = true;
    let errors = {};

    if (!name) {
      isValid = false;
      errors.name = 'Name is required';
    }

    if (!email) {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!this.validateEmail(email)) {
      isValid = false;
      errors.email = 'Invalid email address';
    }

    if (!phoneNumber) {
      isValid = false;
      errors.phoneNumber = 'Phone number is required';
    } else if (!this.validatePhoneNumber(phoneNumber)) {
      isValid = false;
      errors.phoneNumber = 'Invalid phone number';
    }

    if (services.length === 0) {
      isValid = false;
      errors.services = 'At least one service must be selected';
    }

    // if (!budget) {
    //   isValid = false;
    //   errors.budget = 'Budget is required';
    // }

    this.setState({ errors });
    return isValid;
  };

  validateEmail = (email) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  validatePhoneNumber = (phoneNumber) => {
    // Phone number validation regex pattern
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNumber);
  };

  render() {
    const { submitted } = this.state;

    // Redirect to '/thankyou' page after successful submission
    if (submitted) {
      window.location.href = '/thanks';
      return null; // Return null to prevent rendering the rest of the component
    }

    const { name, email, phoneNumber, companyName, companyWebsite, services, 
           // budget, 
           message, errors } = this.state;

    return (
      <section className="container contact-form">
          <a href='https://calendly.com/onceadev/15min' target="_blank" rel="noreferrer" className='button cb' >
            <span data-text="Schedule a call">
                <i>Schedule a call</i>
            </span>
          </a>
        <div className="bg-noise"></div>
        <div className="sub-s2">
          <form onSubmit={this.handleSubmit}>
            <h4 className='fromh4-1'>First, let's get to know each other</h4>
            <div className='p-i'>
              <div>
                {/* <label htmlFor="name">Name *</label> */}
                <input type="text" id="name" name="name" value={name} onChange={this.handleChange} placeholder="Your name"/>
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div>
                {/* <label htmlFor="email">Email Address *</label> */}
                <input type="email" id="email" name="email" value={email} onChange={this.handleChange} placeholder="Your email address"/>
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div>
                {/* <label htmlFor="phoneNumber">Phone Number *</label> */}
                <input type="tel" id="phoneNumber" name="phoneNumber" value={phoneNumber} onChange={this.handleChange} placeholder="Your contact number"/>
                {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
              </div>
              <div>
                {/* <label htmlFor="companyName">Company Name</label> */}
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={companyName}
                  onChange={this.handleChange}
                  placeholder="Company name"
                />
              </div>
              <div>
                {/* <label htmlFor="companyWebsite">Company Website</label> */}
                <input
                  type="text"
                  id="companyWebsite"
                  name="companyWebsite"
                  value={companyWebsite}
                  onChange={this.handleChange}
                  placeholder="Company website"
                />
              </div>
            </div>
            <div>
              <h4>What can we help with?</h4>
              <div className='w-s'>
                <input
                  type="checkbox"
                  name="services"
                  id="service1"
                  value="Brand Identity"
                  checked={services.includes('Brand Identity')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service1' className='button'>Brand Identity</label>

                <input
                  type="checkbox"
                  name="services"
                  id="service2"
                  value="Digital Product Strategy"
                  checked={services.includes('Digital Product Strategy')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service2' className='button'>Digital Product Strategy</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service3"
                  value="Digital Branding"
                  checked={services.includes('Digital Branding')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service3' className='button'>Digital Branding</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service4"
                  value="Website"
                  checked={services.includes('Website')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service4' className='button'>Website</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service5"
                  value="Mobile Application"
                  checked={services.includes('Mobile Application')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service5' className='button'>Mobile Application</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service6"
                  value="UI/UX"
                  checked={services.includes('UI/UX')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service6' className='button'>UI/UX</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service7"
                  value="Web 3"
                  checked={services.includes('Web 3')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service7' className='button'>Web 3</label>
                <input
                  type="checkbox"
                  name="services"
                  id="service8"
                  value="Site Maintenance"
                  checked={services.includes('Site Maintenance')}
                  onChange={this.handleServiceChange}
                />
                <label htmlFor='service8' className='button'>Site Maintenance</label>
                {errors.services && <span className="error">{errors.services}</span>}
              </div>
            </div>
            <div>
              <h4>Tell us about the project</h4>
              <div>
                <textarea name="message" value={message} onChange={this.handleChange} placeholder="Hello!"></textarea>
              </div>
            </div>
            <button type="submit" id="submit-button" className='button submit-button'>Send request</button>
          </form>
          <ContactC/>
        </div>
      </section>
    );
  }
}
