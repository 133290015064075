import React, { Component } from 'react'


export default class TestimonialLead extends Component {


  render() {

    return (
        <div className='testimonial-lead'>
            <div className='testimonial-lead-cont'>
                <h2 data-splitting="chars">
                    We Integrate <br/>Collaborate, and <br/>Challenge. We are <br/>digital natives <br/>embracing the <br/>creative freedom to <br/>produce solution <br/>that connect, <br/>communicate, and <br/>
                    inspire.
                </h2>
            </div>

            <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/hand2.png' width='316' height='382' className='top-hand' alt='decorative hand Onceadev'/>
            <img src='https://ik.imagekit.io/onceadev/assets/tr:f-auto/hand3.png' width='282' height='335' className='bottom-hand' alt='decorative hand Onceadev'/>
        </div>
    )

  }
}
