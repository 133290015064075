import React, { Component } from 'react'

import "./styles/sub.css"
import "./styles/sub-responsive.css"

import Footer from '../main-components/footer'
import Header from '../main-components/header'
import { Helmet } from 'react-helmet'



export default class Lost extends Component {

  render() {

    return (
      <div className='extra-page lost'>
        <Helmet>
          <title>Onceadev 404 Error | Oops! Lost in Digital Space</title>
          <meta name="title" content="Onceadev 404 Error | Oops! Lost in Digital Space" />
          <meta name="description"content="Sorry, the page you're searching for is in a digital hide-and-seek. Our team at Onceadev is on the lookout to get you back on track. Let's navigate together!"/>
          <meta property="og:title" content="404" />
          <meta name="og:description"content="Sorry, the page you're searching for is in a digital hide-and-seek. Our team at Onceadev is on the lookout to get you back on track. Let's navigate together!"/>
        </Helmet>
        <div className='d-none'><Header/></div>
        <h3>Apologies, folks!</h3>
        <p>The page you are looking for is on vacation</p>
        <a href='/' className='button pb' >
            <span data-text="Back to home">
                <i>Back to home</i>
            </span>
        </a>
        <svg viewBox="0 0 1063 472" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M272.351 304.751H331.846V361.602H272.351V464.067H212.196V361.602H0V316.65L206.908 7.93275H272.351V304.751ZM212.196 88.5826L69.41 304.751H212.196V88.5826Z" fill="white" fillOpacity="0.05"/>
          <path d="M530.475 472C478.473 472 436.165 450.185 403.554 406.555C370.942 362.043 354.636 305.191 354.636 236C354.636 166.809 370.942 110.177 403.554 66.1064C435.725 22.0355 478.032 0 530.475 0C582.477 0 624.564 22.0355 656.735 66.1064C689.347 110.177 705.652 166.809 705.652 236C705.652 305.191 689.347 362.043 656.735 406.555C624.564 450.185 582.477 472 530.475 472ZM530.475 413.826C563.527 413.826 590.63 397.3 611.784 364.246C632.937 331.193 643.514 288.444 643.514 236C643.514 183.556 632.937 140.807 611.784 107.753C590.63 74.7003 563.527 58.1737 530.475 58.1737C496.982 58.1737 469.659 74.7003 448.505 107.753C427.352 140.807 416.775 183.556 416.775 236C416.775 288.444 427.352 331.193 448.505 364.246C469.659 397.3 496.982 413.826 530.475 413.826Z" fill="white" fillOpacity="0.05"/>
          <path d="M1003.51 304.751H1063V361.602H1003.51V464.067H943.351V361.602H731.154V316.65L938.062 7.93275H1003.51V304.751ZM943.351 88.5826L800.564 304.751H943.351V88.5826Z" fill="white" fillOpacity="0.05"/>
        </svg>

        <div className='d-none'><Footer/></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    )

  }
}
